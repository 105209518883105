@import "./src/assets/colors";

.ticker_interval_container {
  display: flex;
  width: 100%;
  background: $gradient-header;
  border-bottom: 1px solid $color-black;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  div.panel_tab {
    cursor: pointer;
    flex-basis: 5rem;
    text-align: center;
    padding-bottom: 3px;
    user-select: none;
    border-right: 1px solid $color-black;
    &:first-child {
      border-left: 1px solid $color-black;
    }
    &.selected {
      background: $gradient-button;
    }
    &:hover {
      background: $gradient-selected;
    }
  }
}