@import "../assets/colors";

body * {
  box-sizing: border-box;
  font-size: 0.8rem;
}

.app_container {
  display: grid;
  grid-template-columns: min-content auto 480px;
  @media (max-width: 750px) {
    grid-template-columns: min-content 0 auto;
  }
  @media (max-width: 700px) {
    grid-template-columns: 0 0 100%;
  }
  position: relative;
  margin: 0;
  padding: 0;
  background-color: #282c34;
  min-height: calc(100vh - 48px);
  max-height: calc(100vh - 48px);
  overflow: hidden;
  color: $color-white;
}
/* width */
::-webkit-scrollbar {
  width: 0.125rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: $color-dark-gray;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-dark-gray;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


