@import "./src/assets/colors";

.donate_panel {
  .appbar {
    background: $gradient-header;
  }

  .donate_title {
    margin: 0;
    padding-top: 2rem;
  }

  .donate_paragraph {
    margin: 0;
    padding: 2rem;
  }

  .donate_methods {
    min-height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .donate_method {
      padding: 2rem;

      @media(max-width: 790px) {
        padding: 0;
      }

      Button {
        display: flex;
        justify-items: center;
        flex-direction: column;

        img {
          max-height: 125px;
          max-width: 125px;
          margin-bottom: 1rem;
        }

        svg {
          color: $color-white;
        }
      }

    }
  }
}