@import "./src/assets/colors";

.panel_container {
  height: 100%;
  background-color: $color-dark-gray;
  display: grid;
  grid-template-rows: 100%;
  align-items: center;
  .selected_panel {
    height: 100%;
  }
}