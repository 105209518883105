@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap);
.ticker_side_container{height:100%;background-color:#131722;overflow-y:hidden}.ticker_side_container .ticker_row{display:grid;grid-template-columns:35% 35% 30%;font-weight:700}.ticker_side_container .ticker_row div.ticker_row_cell{padding:0 1rem;border-bottom:1px solid #232632}.ticker_side_container .ticker_row div.ticker_row_cell:last-child{text-align:right}.ticker_side_container .day_high{color:#26a69a}.ticker_side_container .day_high.year{color:#131722;background-color:#26a69a;font-weight:800}.ticker_side_container .day_low{color:#ef5350}.ticker_side_container .day_low.year{color:#131722;background-color:#ef5350;font-weight:800}

.ticker_status_container{display:flex;justify-content:center;align-items:center;background:linear-gradient(180deg, #232632 0%, #131722 100%);grid-column-start:1;grid-column-end:3}.ticker_status_container .circle{height:0.5rem;width:0.5rem;border-radius:50%;margin-right:0.5rem;background-color:#ffb267}.ticker_status_container .circle.connected{background-color:#26a69a}

.ticker_momentum_container{display:grid;grid-template-columns:50% 50%;background-color:#232632;grid-column-start:1;grid-column-end:3}.ticker_momentum_container .momentum_group_container{display:grid;grid-template-rows:0.188rem 0.188rem 0.188rem 0.188rem 0.188rem;grid-row-gap:0.125rem}.ticker_momentum_container .momentum_group_container .momentum_bar_container{width:100%;background-color:#131722;border:0.075rem solid #131722;border-radius:0.313rem}.ticker_momentum_container .momentum_group_container .momentum_bar_container .bar{height:0.188rem;border-radius:0.313rem;border-width:0.075rem;border-style:solid}.ticker_momentum_container .momentum_group_container .momentum_bar_container .bar.high{background-color:#26a69a;border-color:#26a69a}.ticker_momentum_container .momentum_group_container .momentum_bar_container .bar.low{margin:0 0 0 auto;background-color:#ef5350;border-color:#ef5350}

.ticker_banner_container{display:grid;width:100%;grid-column-start:1;grid-column-end:3;grid-template-columns:50% 50%;background:linear-gradient(180deg, #232632 0%, #131722 100%)}.ticker_banner_container .side{font-weight:600;justify-self:center;align-self:center}

.ticker_interval_container{display:flex;width:100%;background:linear-gradient(180deg, #232632 0%, #131722 100%);border-bottom:1px solid #131722;grid-row-start:1;grid-row-end:2;grid-column-start:1;grid-column-end:3}.ticker_interval_container div.panel_tab{cursor:pointer;flex-basis:5rem;text-align:center;padding-bottom:3px;-webkit-user-select:none;-ms-user-select:none;user-select:none;border-right:1px solid #131722}.ticker_interval_container div.panel_tab:first-child{border-left:1px solid #131722}.ticker_interval_container div.panel_tab.selected{background:linear-gradient(180deg, #26a69a 0%, #131722 100%)}.ticker_interval_container div.panel_tab:hover{background:linear-gradient(180deg, #26a69a 0%, #131722 100%)}

.ticker_container{display:grid;max-height:calc(100vh - 48px);grid-template-columns:50% 50%;grid-template-rows:1.25rem 1.25rem 2rem auto 1.25rem}.ticker_container .ticker_sides{grid-column-start:1;grid-column-end:3;display:grid;grid-template-columns:50% 50%;max-height:calc(100vh - (48px + 1.25rem + 1.25rem + 2rem + 1.25rem))}.ticker_container .ticker_sides .ticker_sides:last-child{grid-column-start:2;grid-column-end:3}

.widget_container{display:grid;grid-template-rows:1.25rem auto}.widget_container .header{display:grid;align-items:center;justify-items:center;background:linear-gradient(180deg, #232632 0%, #131722 100%);font-weight:500}.widget_container .rows .row{display:grid;grid-template-columns:33.3% 33.3% 33.3%;align-items:center;justify-items:start}.widget_container .rows .row.high{color:#26a69a}.widget_container .rows .row.low{color:#ef5350}.widget_container .rows .row .column{padding:0 0.5rem;width:100%;border-top:1px solid #232632;border-bottom:1px solid #232632}.widget_container .rows .row .column:last-child{text-align:center}.widget_container .rows .row.title .column{color:#e8edfa;border-top:3px solid #131722;background-color:#232632}

.sidebar_container{display:grid;height:100%;grid-template-columns:-webkit-min-content 1rem;grid-template-columns:min-content 1rem;grid-template-rows:auto 1.25rem;background-color:#131722}.sidebar_container .sidebar_contents{width:240px}.sidebar_container .sidebar_contents .widgets_container{max-height:calc(100vh - 1.25rem);overflow-y:scroll}.sidebar_container .sidebar_contents .bottom_panel{grid-column-start:1;grid-column-end:2;grid-row-start:2;grid-row-end:3;background:linear-gradient(180deg, #232632 0%, #131722 100%);height:1.25rem}.sidebar_container .sidebar_toggle{grid-column-start:2;grid-column-end:3;grid-row-start:1;grid-row-end:3;height:100%;display:flex;justify-content:center;align-items:center;background:linear-gradient(to left, #131722, #161a25, #191c28, #1c1f2b, #1f222e, #1f222e, #1f222e, #1f222e, #1c1f2b, #191c28, #161a25, #131722);padding:0 0.25rem}.sidebar_container .sidebar_toggle .button{cursor:pointer;-webkit-user-select:none;-ms-user-select:none;user-select:none;background-color:#131722;padding:0.75rem 0.25rem;border:1px solid #787b86;border-radius:2.5px}.sidebar_container .sidebar_toggle .button:hover{background-color:#787b86}

.chart_panel_container{height:100%;display:grid;grid-template-rows:20px auto}.chart_panel_container .toolbar{max-height:18px;display:flex}.chart_panel_container .toolbar .item{cursor:pointer;flex-basis:2rem;padding:0.25rem;border:1px solid #26a69a;display:flex;justify-content:center;align-items:center}.chart_panel_container .toolbar .item.selected{background:linear-gradient(180deg, #26a69a 0%, #131722 100%)}.chart_panel_container .toolbar .item:hover{background:linear-gradient(180deg, #26a69a 0%, #131722 100%)}.chart_panel_container .charts{height:100%;display:grid;grid-template-columns:50% 50%;grid-template-rows:50% 50%}@media (max-width: 750px){.chart_panel_container{display:none}}

.home_panel_container{padding:1rem}.home_panel_container h1{font-size:2rem}.home_panel_container h2{font-size:1.6rem}.home_panel_container p{font-size:1rem}

.panel_container{height:100%;background-color:#232632;display:grid;grid-template-rows:100%;align-items:center}.panel_container .selected_panel{height:100%}

.navbar_container .navbar_toolbar{display:flex;justify-content:space-between}.navbar_container .navbar_image{display:flex;align-items:center}.navbar_container .navbar_image img{cursor:pointer}@media (max-width: 397.5px){.navbar_container .navbar_image img{max-width:175px}}@media (max-width: 370px){.navbar_container .navbar_image img{max-width:150px}}@media (max-width: 350px){.navbar_container .navbar_image img{max-width:125px}}@media (max-width: 330px){.navbar_container .navbar_image img{max-width:115px}}@media (max-width: 630px){.navbar_container .navbar_banner{display:none}}.navbar_container .navbar{background:linear-gradient(180deg, #232632 0%, #131722 100%)}.navbar_container .user_profile{display:flex;justify-content:center;align-items:center;cursor:pointer}.navbar_container .user_profile span{padding-right:0.5rem;font-weight:500;text-transform:uppercase}@media (max-width: 700px){.navbar_container .user_profile span{display:none}}

.auth_container .auth_title{text-align:center}.auth_container .auth_content{display:flex;justify-content:center}.auth_container .auth_actions{display:flex;justify-content:space-between}.auth_container .tab_selected{color:#26a69a}.auth_container .auth_captcha{padding:1rem 1rem 0;display:flex;justify-content:center}.auth_container p.existing_code{cursor:pointer;color:#26a69a;text-align:center}

.user_panel .appbar{background:linear-gradient(180deg, #232632 0%, #131722 100%)}.user_panel .user_profile_container,.user_panel .user_subscription_container{min-height:50vh}

.donate_panel .appbar{background:linear-gradient(180deg, #232632 0%, #131722 100%)}.donate_panel .donate_title{margin:0;padding-top:2rem}.donate_panel .donate_paragraph{margin:0;padding:2rem}.donate_panel .donate_methods{min-height:35vh;display:flex;justify-content:center;align-items:center;flex-wrap:wrap}.donate_panel .donate_methods .donate_method{padding:2rem}@media (max-width: 790px){.donate_panel .donate_methods .donate_method{padding:0}}.donate_panel .donate_methods .donate_method Button{display:flex;justify-items:center;flex-direction:column}.donate_panel .donate_methods .donate_method Button img{max-height:125px;max-width:125px;margin-bottom:1rem}.donate_panel .donate_methods .donate_method Button svg{color:#e8edfa}

body *{box-sizing:border-box;font-size:0.8rem}.app_container{display:grid;grid-template-columns:-webkit-min-content auto 480px;grid-template-columns:min-content auto 480px;position:relative;margin:0;padding:0;background-color:#282c34;min-height:calc(100vh - 48px);max-height:calc(100vh - 48px);overflow:hidden;color:#e8edfa}@media (max-width: 750px){.app_container{grid-template-columns:-webkit-min-content 0 auto;grid-template-columns:min-content 0 auto}}@media (max-width: 700px){.app_container{grid-template-columns:0 0 100%}}::-webkit-scrollbar{width:0.125rem}::-webkit-scrollbar-track{background:#232632}::-webkit-scrollbar-thumb{background:#232632}::-webkit-scrollbar-thumb:hover{background:#555}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


