.ticker_container {
  display: grid;
  max-height: calc(100vh - 48px);
  grid-template-columns: 50% 50%;
  grid-template-rows: 1.25rem 1.25rem 2rem auto 1.25rem;
  .ticker_sides {
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 50% 50%;
    max-height: calc(100vh - (48px + 1.25rem + 1.25rem + 2rem + 1.25rem));
    .ticker_sides:last-child {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }
}