@import "./src/assets/colors";

.ticker_status_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gradient-header;
  grid-column-start: 1;
  grid-column-end: 3;
  .circle {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 50%;
      margin-right: 0.5rem;
    background-color: $color-orange;
    &.connected {
      background-color: $color-green;
    }
  }
}