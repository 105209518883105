$color-green: #26a69a;
$color-red: #ef5350;
$color-dark-gray: #232632;
$color-light-gray: #787b86;
$color-black: #131722;
$color-white: #e8edfa;
$color-orange: #ffb267;
$color-blue: #153cf1;
$color-light-blue: #153cf1;

$gradient-header: linear-gradient(180deg, $color-dark-gray 0%, $color-black 100%);
$gradient-side: linear-gradient(to left, #131722, #161a25, #191c28, #1c1f2b, #1f222e, #1f222e, #1f222e, #1f222e, #1c1f2b, #191c28, #161a25, #131722);
$gradient-selected: linear-gradient(180deg, $color-green 0%, $color-black 100%);
$gradient-button: linear-gradient(180deg, $color-green 0%, $color-black 100%);