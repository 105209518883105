@import "./src/assets/colors";

.ticker_momentum_container {
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: $color-dark-gray;
  grid-column-start: 1;
  grid-column-end: 3;
  .momentum_group_container {
    display: grid;
    grid-template-rows: 0.188rem 0.188rem 0.188rem 0.188rem 0.188rem;
    grid-row-gap: 0.125rem;
    .momentum_bar_container {
      width: 100%;
      background-color: $color-black;
      border: 0.075rem solid $color-black;
      border-radius: 0.313rem;
      .bar {
        height: 0.188rem;
        border-radius: 0.313rem;
        border-width: 0.075rem;
        border-style: solid;
        &.high {
          background-color: $color-green;
          border-color: $color-green;
        }
        &.low {
          margin: 0 0 0 auto;
          background-color: $color-red;
          border-color: $color-red;
        }
      }
    }
  }
}