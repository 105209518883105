@import "../../../assets/colors";

.chart_panel_container {
  height: 100%;
  display: grid;
  grid-template-rows: 20px auto;
  .toolbar {
    max-height: 18px;
    display: flex;
    .item {
      cursor: pointer;
      flex-basis: 2rem;
      padding: 0.25rem;
      border: 1px solid $color-green;
      display: flex;
      justify-content: center;
      align-items: center;
      &.selected {
        background: $gradient-button;
      }
      &:hover {
        background: $gradient-selected ;
      }
    }
  }
  .charts {
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
  }

  @media (max-width: 750px) {
    display: none;
  }
}