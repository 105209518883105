@import "./src/assets/colors";

.ticker_banner_container {
  display: grid;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-template-columns: 50% 50%;
  background: $gradient-header;
  .side {
    font-weight: 600;
    justify-self: center;
    align-self: center;
  }
}