@import "./src/assets/colors";

.sidebar_container {
  display: grid;
  height: 100%;
  grid-template-columns: min-content 1rem;
  grid-template-rows: auto 1.25rem;
  background-color: $color-black;
  .sidebar_contents {
    width: 240px;
    .widgets_container {
      max-height: calc(100vh - 1.25rem);
      overflow-y: scroll;
    }
    .bottom_panel {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
      background: $gradient-header;
      height: 1.25rem;
    }
  }
  .sidebar_toggle {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gradient-side;
    padding: 0 0.25rem;
    .button {
      cursor: pointer;
      user-select: none;
      background-color: $color-black;
      padding: 0.75rem 0.25rem;
      border: 1px solid $color-light-gray;
      border-radius: 2.5px;
      &:hover {
        background-color: $color-light-gray;
      }
    }
  }
}