@import "./src/assets/colors";

.widget_container {
    display: grid;
    grid-template-rows: 1.25rem auto;
    .header {
        display: grid;
        align-items: center;
        justify-items: center;
        background: $gradient-header;
        font-weight: 500;
    }
    .rows .row {
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        align-items: center;
        justify-items: start;
        &.high {
            color: $color-green;
        }
        &.low {
            color: $color-red;
        }
        .column {
            padding: 0 0.5rem;
            width: 100%;
            border-top: 1px solid $color-dark-gray;
            border-bottom: 1px solid $color-dark-gray;
            &:last-child {
                text-align: center;
            }
        }
        &.title .column {
            color: $color-white;
            border-top: 3px solid $color-black;
            background-color: $color-dark-gray;
        }
    }
}