@import "./src/assets/colors";

.ticker_side_container {
  height: 100%;
  background-color: $color-black;
  overflow-y: hidden;
  .ticker_row {
    display: grid;
    grid-template-columns: 35% 35% 30%;
    font-weight: 700;
    div.ticker_row_cell {
      padding: 0 1rem;
      border-bottom: 1px solid $color-dark-gray;
    }
    div.ticker_row_cell:last-child {
      text-align: right;
    }
  }
  .day_high {
    color: $color-green;
    &.year {
      color: $color-black;
      background-color: $color-green;
      font-weight: 800;
    }
  }
  .day_low {
    color: $color-red;
    &.year {
      color: $color-black;
      background-color: $color-red;
      font-weight: 800;
    }
  }
}