@import "./src/assets/colors";

.auth_container {
  .auth_title {
    text-align: center;
  }
  .auth_content {
    display: flex;
    justify-content: center;
  }
  .auth_actions {
    display: flex;
    justify-content: space-between;
  }
  .tab_selected {
    color: $color-green;
  }
  .auth_captcha {
    padding: 1rem 1rem 0;
    display: flex;
    justify-content: center;
  }
  p.existing_code {
    cursor: pointer;
    color: $color-green;
    text-align: center;
  }
}