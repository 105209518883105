@import "./src/assets/colors";

.navbar_container {
  .navbar_toolbar {
    display: flex;
    justify-content: space-between;
  }

  .navbar_image {
    display: flex;
    align-items: center;
    img {
      cursor: pointer;

      @media(max-width: 397.5px) {
        max-width: 175px;
      }

      @media(max-width: 370px) {
        max-width: 150px;
      }

      @media(max-width: 350px) {
        max-width: 125px;
      }

      @media(max-width: 330px) {
        max-width: 115px;
      }
    }
  }

  .navbar_banner {
    @media(max-width: 630px) {
      display: none;
    }
  }

  .navbar {
    background: $gradient-header;
  }

  .user_profile {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      padding-right: 0.5rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    @media(max-width: 700px) {
        span {
          display: none;
        }
    }
  }
}